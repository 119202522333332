import React, { FC, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NoteIcon from "@mui/icons-material/Note";
import PeopleIcon from "@mui/icons-material/People";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Store } from "../store";
import types from "../store/types";
import { UserTypeTeacher } from "../common/type";

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Store);
  const { isAuthenticated, logout } = useAuth0();

  const handleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      closeDrawer();
    };

  const closeDrawer = () => {
    dispatch({ type: types.SIDEBAR_CLICK });
  };

  const handleLogout = async () => {
    try {
      if (isAuthenticated) {
        logout({ returnTo: window.location.origin });
      }
      dispatch({ type: types.SIDEBAR_CLICK });
    } catch (e) {
      alert(e);
    }
  };

  const changeRoute = (url: string) => {
    dispatch({ type: types.SIDEBAR_CLICK });
    navigate(url);
  };

  const menuList = () => (
    <>
      <List>
        <>
          <ListItem
            button
            key="dashboard"
            onClick={() => changeRoute("/menuDashboard")}
          >
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="ダッシュボード" />
          </ListItem>
          <ListItem
            button
            key="reports"
            onClick={() => changeRoute(`/menuReports`)}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="記録一覧" />
          </ListItem>
          <ListItem
            button
            key="new-report"
            onClick={() => changeRoute(`/menuReportDetail`)}
          >
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary="記録作成" />
          </ListItem>
        </>
        {/* TODO：「教員」ユーザのみ表示 */}
        {/* {state.accountType === "teacher" && ( */}
        {state.userType === UserTypeTeacher && (
          <>
            <ListItem
              button
              key="users"
              onClick={() => changeRoute("/menuUsers")}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="学生一覧" />
            </ListItem>
          </>
        )}
        {/* )} */}
        <ListItem button key="logout" onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="ログアウト" />
        </ListItem>
      </List>
    </>
  );

  return (
    <div>
      <Drawer anchor="left" open={state.sidebarState} onClose={handleDrawer()}>
        {menuList()}
      </Drawer>
    </div>
  );
};

export default Sidebar;
