import { Box  } from "@mui/material";
import { InputLabelAtom } from "../atoms/InputLabelAtom";
import { PaddingAtom } from "../atoms/PaddingAtom";

export const InputFormMolecule = (props: any) => {
  const { inputLabel } = props;
  return (
    <>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "0 0 0 0",
      }}
    >
      <InputLabelAtom>{inputLabel}</InputLabelAtom>
      {props.children}
    </Box>
    <PaddingAtom></PaddingAtom>
    </>
  );
};
