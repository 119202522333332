import React, { useState, useEffect, useContext } from "react";

import { PaddingAtom } from "../components/atoms/PaddingAtom";
import { LatestTagMolecule } from "../components/molecules/LatestTagMolecule";
import { RankingMolecule } from "../components/molecules/RankingMolecule";
import { RaderChartComponent } from "../components/RaderChartComponent";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import {
  getDashboard,
  getStandardTags,
  useAuth0Token,
} from "../common/http-requests";
import {
  dashboardRequestType,
  dashboardResponseType,
  standardTagsResponseType,
} from "../common/type";
import { CircularProgress, Grid, Typography, Container } from "@mui/material";

import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { useLocation } from "react-router-dom";
import { Store } from "../store";

const DashboardPage = () => {
  const [isSending, setIsSending] = useState(true);
  // 学生一覧画面から遷移してきた場合
  const location = useLocation();
  const { userId, userName } = location.state;

  const { state } = useContext(Store);

  //鳴門パースペクティブ
  const [standardTags, setStandardTags] = useState<standardTagsResponseType>({
    content: [],
  });

  const [dashboardData, setDashboardData] = useState<
    dashboardResponseType | undefined
  >(undefined);

  // すべて表示
  const [isOpen, setIsOpen] = useState(false);

  // ランキング項目の表示非表示
  const [isAccordionOpen, setIsAccordionOpen] = useState([
    {
      type: "standard",
      isOpen: false,
    },
    {
      type: "user",
      isOpen: false,
    },
  ]);

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  const { getToken } = useAuth0Token();

  const initialHideState = dashboardData?.standard_tag_totals_student.map(
    (report) => {
      return {
        name: report.report_date_half,
        hide: false,
        type: "login_user",
      };
    }
  );
  dashboardData?.standard_tag_totals_all_students.map((report) => {
    initialHideState?.push({
      name: report.report_date_half,
      hide: false,
      type: "all_students",
    });
  });

  useEffect(() => {
    (async () => {
      await getDashboardsData(userId ? Number(userId) : Number(state.userId));
      getStandardTagsData();
    })();
  }, []);

  const getDashboardsData = async (
    login_user_id: number // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: dashboardRequestType = {
        login_user_id, // userResponseType.id の値
      };
      const data: dashboardResponseType = await getDashboard(
        await getToken(),
        requestParams
      );
      setDashboardData(data);
    } catch {
    } finally {
      setIsSending(false);
    }
  };

  // 鳴門パースペクティブ
  const getStandardTagsData = async () => {
    setIsSending(true);
    try {
      const data: standardTagsResponseType = await getStandardTags(
        await getToken()
      );
      setStandardTags(data);
    } catch (error) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ダッシュボード情報を取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const tagTypes = [
    "C1) 構え",
    "C2) 知識",
    "C3) 技能",
    "C4) 実践的指導力",
    "T1) 人間性",
    "T2) 連携・協働力",
    "T3) 課題発見・価値創造力",
    "T4) 省察力・職能成長志向",
  ];

  // ログインユーザーのチャートデータ
  const userChartData = tagTypes.map((tag) => {
    return {
      tag_type_name: tag,
    };
  });
  userChartData.map((value, index) => {
    dashboardData?.standard_tag_totals_student.map((report) => {
      return (
        // @ts-ignore
        (value[report.report_date_half] = report.count_reports[index])
      );
    });
  });

  // 全学生のチャートデータ
  const allStudentsChartData = tagTypes.map((tag, index) => {
    return {
      tag_type_name: tag,
    };
  });
  allStudentsChartData.map((value, index) => {
    dashboardData?.standard_tag_totals_all_students.map((report) => {
      return (
        // @ts-ignore
        (value[report.report_date_half] = report.count_reports[index])
      );
    });
  });

  // ランキングをmapで回す
  const rankingTypes = ["standard", "user"];
  const aggregateTypes = ["target_user", "all_students"];

  // 該当のランキングのアコーディオンが開いているかの確認
  const accordionState = (rankingType: any) => {
    return isAccordionOpen.find((obj: any) => {
      return obj.type === rankingType;
    })?.isOpen;
  };

  return (
    <Container maxWidth="xl">
      <div>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <Typography
              variant="h5"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              ダッシュボード
            </Typography>
          </Grid>
        </Grid>
      </div>

      {isSending ? (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <div className="text-center">
                <CircularProgress
                  style={{ margin: "10rem auto 0 auto", textAlign: "center" }}
                  size="3rem"
                />
              </div>
              <Typography>ダッシュボード情報を取得中...</Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {feedbackInfo.type === "error" ? (
            <FeedbackBar
              feedbackInfo={feedbackInfo}
              handleClose={handleClose}
            ></FeedbackBar>
          ) : (
            <>
              <PaddingAtom></PaddingAtom>
              <div className="relative">
                <table className="w-[100%] text-sm text-left">
                  <thead className="text-xs uppercase text-green-500">
                    <tr>
                      <th scope="col" className="text-center text-xl w-[50%]">
                        {userName ? userName : "自分"}
                      </th>
                      <th scope="col" className="text-center text-xl">
                        学生全体
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      {aggregateTypes.map((aggregateType) => {
                        return (
                          <td key={aggregateType} className="px-6 py-4">
                            <div className="w-fit mx-auto rounded-lg shadow-lg bg-white">
                              <RaderChartComponent
                                chartData={
                                  aggregateType === "target_user"
                                    ? userChartData
                                    : allStudentsChartData
                                }
                                dashboardData={dashboardData}
                                type={aggregateType}
                                standardTags={standardTags}
                              />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                    {rankingTypes.map((rankingType, index) => {
                      // 変数化
                      const standardRankingType = rankingType === "standard";
                      const userRankingType = rankingType === "user";

                      const rankingTitle = standardRankingType
                        ? "鳴門パースペクティブ ランキング"
                        : "オリジナルタグ ランキング";

                      // アコーディオン
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <PaddingAtom />
                            </td>
                          </tr>
                          <tr className="shadow-lg mt-4">
                            <th colSpan={2} className="w-full h-fit">
                              <button
                                type="button"
                                className="bg-white flex items-center justify-between w-full p-5 
                                border -0 border-gray-200 rounded-t-xl 
                                text-lg"
                                onClick={() => {
                                  setIsAccordionOpen(
                                    isAccordionOpen.map((obj: any) => {
                                      if (obj.type === rankingType) {
                                        return {
                                          ...obj,
                                          isOpen: !obj.isOpen,
                                        };
                                      } else {
                                        return {
                                          ...obj,
                                        };
                                      }
                                    })
                                  );
                                }}
                              >
                                <div className="flex items-center font-bold">
                                  <p>{rankingTitle}</p>
                                  <span className="group relative ml-2">
                                    <div className="text-green-500">
                                      <HelpOutlineIcon className="-translate-y-[3px]" />
                                    </div>
                                    <span
                                      id="tooltip-default"
                                      role="tooltip"
                                      className="whitespace-nowrap absolute -top-12 left-1/2 -translate-x-1/2 
                                      z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300
                                      bg-gray-500 rounded-lg before:content-[''] before:absolute 
                                      before:-translate-x-1/2 before:left-1/2 before:top-full 
                                      before:border-4 before:border-transparent before:border-t-gray-500 
                                      shadow-sm opacity-0 group-hover:opacity-100 tooltip dark:bg-gray-700 
                                      pointer-events-none"
                                    >
                                      タグや代表文をクリックすると、記録一覧を表示します
                                    </span>
                                  </span>
                                </div>
                                <svg
                                  data-accordion-icon
                                  className={`w-6 h-6 shrink-0 transition-transform duration-200
                                  ${
                                    accordionState(rankingType)
                                      ? "rotate-180"
                                      : "rotate-0"
                                  }`}
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </th>
                          </tr>
                          <tr
                            id="accordion-color-body-1"
                            className="bg-[#ECECEC] shadow-lg"
                            aria-labelledby="accordion-color-heading-1"
                          >
                            {aggregateTypes.map((aggregateType, index) => {
                              return (
                                <td
                                  key={index}
                                  colSpan={1}
                                  className={`align-top`}
                                >
                                  {accordionState(rankingType) && (
                                    <PaddingAtom />
                                  )}
                                  <div
                                    className={`pr-4 shadow-lg 
                                    bg-white rounded-lg
                                    w-[550px] mx-auto h-full transition-all duration-500 linear ${
                                      accordionState(rankingType)
                                        ? "opacity-1 max-h-[10000px]"
                                        : "opacity-0 max-h-0 overflow-hidden"
                                    }`}
                                  >
                                    <RankingMolecule
                                      dashboardData={dashboardData}
                                      rankingType={rankingType}
                                      aggregateType={aggregateType}
                                      isOpen={isOpen}
                                      setIsOpen={setIsOpen}
                                      standardTags={standardTags}
                                      tagTypes={tagTypes}
                                    />
                                  </div>
                                  {accordionState(rankingType) && (
                                    <PaddingAtom />
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                    <tr>
                      <td>
                        <PaddingAtom />
                      </td>
                    </tr>
                    <tr className="shadow-lg">
                      <th colSpan={2} className="w-full h-fit">
                        <span
                          className="flex items-center justify-between w-full p-5 
                                border -0 border-gray-200 rounded-t-xl 
                                text-lg bg-white"
                        >
                          <h3 className="font-bold">
                            最近使用したオリジナルタグ
                          </h3>
                        </span>
                      </th>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800 bg-[#ECECEC] shadow-lg">
                      {aggregateTypes.map((aggregateType, index) => {
                        return (
                          <td key={index} className="px-10 py-4">
                            <div
                              className={`pr-4 shadow-lg 
                                    bg-white rounded-lg py-4
                                    w-[550px] mx-auto h-full transition-all duration-500 linear`}
                            >
                              <LatestTagMolecule
                                dashboardData={dashboardData}
                                aggregateType={aggregateType}
                              />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
                <PaddingAtom></PaddingAtom>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardPage;
