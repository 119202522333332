import { createTheme } from "@mui/material/styles";

export const myTheme = createTheme({
  palette: {
    primary: {
      main: '#4BA82C',
    },
    // secondary: {
    //   main: '#1C4196',
    // },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      "Arial",
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      '"BIZ UDPGothic"',
      "Meiryo",
      "sans-serif",
    ].join(","),

    // ↓ from amano-enzyme-bkms

    // h1: {
    //   fontSize: 24,
    //   fontWeight: 700,
    // },
    // h2: {
    //   fontSize: 20,
    //   fontWeight: 700,
    // },
  },
  // components: {
  //   MuiTypography: {
  //     styleOverrides: {
  //       h1: {
  //         marginBottom: "20px",
  //         letterSpacing: "0.1em",
  //       },
  //       h2: {
  //         marginBottom: "16px",
  //         letterSpacing: "0.1em",
  //       },
  //     },
  //   },
  //   MuiInputLabel: {
  //     styleOverrides: {
  //       root: {
  //         fontWeight: "700",
  //         fontSize: "16px",
  //         lineHeight: "23px",
  //         letterSpacing: "0.1em",
  //         color: "#222222",
  //         position: "relative",
  //       },
  //     },
  //   },
  //   MuiSelect: {
  //     styleOverrides: {
  //       select: {
  //         background: "#FFFFFF",
  //         border: "1px solid #CDCDCD",
  //         borderRadius: "4px",
  //         fontSize: "15px",
  //         width: "180px",
  //       },
  //     },
  //   },
  //   MuiInputBase: {
  //     styleOverrides: {
  //       input: {
  //         background: "#FFFFFF",
  //         fontSize: "15px",
  //       },
  //     },
  //   },
  //   MuiTextField: {
  //     styleOverrides: {
  //       root: {
  //         background: "#FFFFFF",
  //         border: "1px solid #CDCDCD",
  //         borderRadius: "4px",
  //       },
  //     },
  //   },
  //   MuiSvgIcon: {
  //     styleOverrides: {
  //       root: {
  //         display: 'inline-flex',
  //         verticalAlign: 'middle',
  //       }
  //     },
  //   },
  //   MuiFormControl: {
  //     styleOverrides: {
  //       root: {
  //         maxWidth: "160px",
  //       },
  //     },
  //   },
  //   MuiPaginationItem: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: 0,
  //         "&:hover, &.Mui-selected, &.Mui-selected:hover": {
  //           "color": "#FFFFFF",
  //           "background": "#333333",
  //         },
  //       },
  //     },
  //   },
  //   MuiTable: {
  //     styleOverrides: {
  //       root: {
  //         border: "1px solid #CDCDCD",
  //       },
  //     },
  //   },
  //   MuiTableHead: {
  //     styleOverrides: {
  //       root: {
  //         borderBottom: "1px solid #CDCDCD",
  //       },
  //     },
  //   },
  //   MuiTableRow: {
  //     styleOverrides: {
  //       head: {
  //         background: "#F5F5F5",
  //       },
  //       root: {
  //         "&:last-child td, &:last-child th": {
  //           borderBottom: 0
  //         },
  //       },
  //     },
  //   },
  //   MuiTableCell: {
  //     styleOverrides: {
  //       root: {
  //         padding: "6px 8px",
  //       },
  //       head: {
  //         color: "#777777",
  //       },
  //     },
  //   },
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         letterSpacing: "0.1em",
  //       },
  //     },
  //   },
  // },
});
