import { BasicTextAtom } from "./BasicTextAtom";
import {
  Box,
} from "@mui/material";

export const InputLabelAtom = (props: any) => {
  return (
    <Box sx={{width: "200px"}}>
    <BasicTextAtom>
      {props.children}
    </BasicTextAtom>
    </Box>
  );
};

