export const UserTypeTeacher = 0;
export const UserTypeStudent = 1;

export type loggingRequestType = {
  log_text: string, // ログ文字列
};

export type loggingResponseType = {
  log_text: string, // ログ文字列
};

export type userRequestType = {
  email: string,
};

export type usersRequestType = {
  page_index?: number;  // 0起点（0が1ページ目）
  count_per_page?: number;  // ページ毎の表示データ数
  user_names?: string,  // 氏名の空白区切り
  student_numbers?: string, // 学籍番号の空白区切り
};

export type reportsRequestType = {
  page_index?: number;
  count_per_page?: number;
  login_user_id?: number;  // userResponseType.id の値
  login_user_type?: number;  // 0：教職員、1：学生
  start_date?: string;     // yyyy/mm/dd形式
  end_date?: string;     // yyyy/mm/dd形式
  report_types?: number[];  // 0：週報、1：実習、2：省察、99：その他  TODO：UIと要調整
  standard_tags?: string;  // 数値の空白区切り。数値は standardTagResponseType.id の値
  user_tags?: string;      // タグ文字列の空白区切り。タグ文字列は任意。
  student_numbers?: string;
};

export type reportType = { id: number, value: string }

export type reportRequestType = {
  report_id: number, // reportDetailResponseType.id の値
};

export type newReportRequestType = {
  login_user_id?: number;  // userResponseType.id の値
  date?: string;     // yyyy/mm/dd形式
  report_type?: number;  // 0：週報、1：実習、2：省察、99：その他
  standard_tags?: string;  // 数値の空白区切り。数値は standardTagResponseType.id の値
  user_tags?: string;      // タグ文字列の空白区切り。タグ文字列は任意。
  main_text1?: string;
  main_text2?: string;
};

export type updateReportRequestType = {
  login_user_id?: number;  // userResponseType.id の値
  date?: string;     // yyyy/mm/dd形式
  report_type?: number;  // 0：週報、1：実習、2：省察、99：その他
  standard_tags?: string;  // 数値の空白区切り。数値は standardTagResponseType.id の値
  user_tags?: string;      // タグ文字列の空白区切り。タグ文字列は任意。
  main_text1?: string;
  main_text2?: string;
  report_id?: number, // reportDetailResponseType.id の値
};

export type dashboardRequestType = {
  login_user_id: number;  // userResponseType.id の値
};

// 学生一覧画面 グラフ
export type usersResponseType = {
  total_count: number;
  content: Array<userReportsResponseType>;
};
export type userReportsResponseType = {
  id: number; // 内部ID（デバッグ用）
  email: string;
  user_name: string;
  user_type: number;
  student_number: string;
  count_reports: number;
};

export type userResponseType = {
  id: number;
  email: string;
  user_name: string;
  user_type: number;  // 0：教職員、1：学生
  student_number: string;
};

// 鳴門パースペクティブ一覧
export type standardTagsResponseType = {
  content: Array<standardTagResponseType>;
};
export type standardTagResponseType = {
  id: number; // 内部ID（デバッグ用）
  tag_type: number;
  tag_name: string;
};

// 記録詳細画面
export type reportDetailResponseType = {
  id: number;   // 内部ID
  // user_id: number;
  report_date: string;
  report_type: number;
  main_text1: string;
  main_text2: string;
  standard_tags: Array<string>;
  user_tags: Array<string>;
};

export type mainSummaryTextType = {
  type: number; // 0：通常分、1：代表文
  text: string;
};

export type reportResponseType = {
  id: number;   // 内部ID
  user_id: number;
  report_date: string;
  report_type: number;
  main_texts1: Array<mainSummaryTextType>;
  main_texts2: Array<mainSummaryTextType>;
  standard_tags: Array<string>;
  user_tags: Array<string>;
};

export type newReportResponseType = {
  id: number;   // 新しく作成された記録のid
};

export type reportsResponseType = {
  total_count: number
  content: Array<reportResponseType>;
};

export type reportsCsvResponseType = {
  content: string;
};

// ダッシュボード画面
export type dashboardResponseType = {
  standard_tag_totals_student: Array<dashboardStandardTagTotalResponseType>;
  standard_tag_totals_all_students: Array<dashboardStandardTagTotalResponseType>;
  standard_tag_ranking_student: Array<dashboardTagRankingResponseType>;
  standard_tag_ranking_all_students: Array<dashboardTagRankingResponseType>;
  user_tag_ranking_student: Array<dashboardTagRankingResponseType>;
  user_tag_ranking_all_students: Array<dashboardTagRankingResponseType>;
  latest_user_tags_student: Array<dashboardLatesetUserTagResponseType>;
  latest_user_tags_all_students: Array<dashboardLatesetUserTagResponseType>;
};
// ダッシュボード画面 グラフ
export type dashboardStandardTagTotalResponseType = {
  report_date_half: string; // 表記例："2022H1", "2022H2", etc
  count_reports: Array<number>; // 8個の要素からなる数値配列。順番に C1,C2,C3,C4,T1,T2,T3,T4 の記録数。
};
// ダッシュボード画面 鳴門パースペクティブランキング
// ダッシュボード画面 オリジナルタグランキング
export type dashboardTagRankingResponseType = {
  id: number; // 内部ID
  tag_name: string; // タグ名
  count_reports: number;  // 記録数
  latest_report_date: string; // yyyy/mm/dd形式
  summary_text: string; // 要約文
};
// ダッシュボード画面 最近使用したオリジナルタグ
export type dashboardLatesetUserTagResponseType = {
  id: number; // 内部ID
  tag_name: string; // タグ名
  latest_report_date: string; // yyyy/mm/dd形式
};
