export const ResetIsHide = (props: any) => {
  const { setIsHide, isHide, className } = props;
  return (
    <div
      className={`bg-red-200 z-[100] opacity-0 ${className && className}`}
      onMouseMove={() => {
        // マウスオーバーするとisHide stateをリセット
        setIsHide(
          isHide?.map((obj: any) => {
            return {
              ...obj,
              hide: false,
            };
          })
        );
      }}
    ></div>
  );
};
