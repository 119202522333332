import { Typography } from "@mui/material";

export const FormOutlineAtom = (props: any) => {
  const { error, mainTextLength, mainText } = props;
  return (
    <>
      <span
        className={`outline h-fit block ${
          error === "error"
            ? "outline-red-600 outline-3"
            : "outline-gray-300 outline-1"
        } rounded-sm`}
      >
        {props.children}
      </span>
      <div className="flex flex-row-reverse items-center justify-between">
        {mainText && 
          <Typography style={{  }}>
            {`${mainTextLength ? mainTextLength : "0"}/1000`}
          </Typography>
        }
        {error === "error" && (
          <div
            className="p-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span className="font-medium">エラー：</span>{" "}
            1000文字以内で入力してください
          </div>
        )}
      </div>
    </>
  );
};
