import React, { FC, useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  ListItemText,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  loggingRequestType,
  loggingResponseType,
  newReportRequestType,
  newReportResponseType,
  reportDetailResponseType,
  reportRequestType,
  reportType,
  standardTagsResponseType,
  updateReportRequestType,
} from "../common/type";

import {
  useAuth0Token,
  getStandardTags,
  newReport,
  getReport,
  updateReport,
  sendLog,
} from "../common/http-requests";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

import { Store } from "../store";
import { BasicTextAtom } from "../components/atoms/BasicTextAtom";
import { FormOutlineAtom } from "../components/atoms/FormOutlineAtom";
import { InputFormMolecule } from "../components/molecules/InputFormMolecule";
import { DatePickerComponent } from "../components/DatePickerComponent";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { useLocation } from "react-router-dom";

export const ReportDetailPage: FC = () => {
  const { getToken } = useAuth0Token();
  const [isSending, setIsSending] = useState(true);
  const { state } = useContext(Store);
  // 記録一覧から遷移してきた場合
  const location = useLocation();
  const { userId, reportId } = location.state;

  // 記録作成した後に、作成した記録でreportIdを書き換えるため
  const [reportIdState, setReportIdState] = useState<number>(reportId);

  //日付登録
  const Today = new Date();
  const [reportDate, setReportDate] = useState<Date>(Today);
  registerLocale("ja", ja);

  // 記録種類のリスト
  const [reportType, setReportType] = useState<number>(0);
  const reportTypesArray: Array<reportType> = [
    { id: 0, value: "週録" },
    { id: 1, value: "実習" },
    { id: 2, value: "省察" },
    { id: 99, value: "その他" },
  ];
  //鳴門パースペクティブ
  const [standardTags, setStandardTags] = useState<standardTagsResponseType>({
    content: [],
  });
  // multipleセレクトボックスを使用するために一度配列形式に変換
  const [selectedStandardTagsArray, setSelectedStandardTagsArray] = useState<
    Array<string>
  >([]);

  //鳴門パースペクティブを空白区切りの文字列に変換
  const [selectedStandardTags, setSelectedStandardTags] = useState<string>("");

  // タグ入力に使用する
  const [userTags, setUserTags] = useState<string>("");

  const [mainText1, setMainText1] = useState<string>("");
  const [mainText2, setMainText2] = useState<string>("");

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // サイドバーの「記録詳細」ボタンを押した際にフォームの中身を空にするため
  const setEmptyReport = () => {
    setReportDate(Today);
    setReportType(0);
    setSelectedStandardTagsArray([]);
    setUserTags("");
    setMainText1("");
    setMainText2("");
  };
  // 鳴門パースペクティブを取得した後に記録一覧を取得
  useEffect(() => {
    (async () => {
      await getStandardTagsData();
      if (reportIdState) {
        getReportData(Number(reportIdState));
      } else {
        setEmptyReport();
      }
    })();
    // サイドバーの「記録詳細」ボタンを押した際に発火するように
  }, [location]);

  // YYYY/MM/DDに変換
  const parseDate = (period: Date | null) => {
    const year = period?.getFullYear();
    // getMonthの値は0起点のため+1
    const month = period ? period.getMonth() + 1 : undefined;
    const date = period?.getDate();
    return `${year}/${month}/${date}`;
  };

  const validStartDateFromDate = (date: Date) => {
    const validStartYear =
      date.getMonth() >= 0 && date.getMonth() < 3
        ? date.getFullYear() - 1
        : date.getFullYear();
    const validStartMonth = date.getMonth() >= 3 && date.getMonth() < 9 ? 3 : 9;
    const validStartDate = new Date(validStartYear, validStartMonth, 1);
    return validStartDate;
  };

  // 鳴門パースペクティブを取得してセレクトボックスに表示
  const getStandardTagsData = async () => {
    setIsSending(true);
    try {
      const data: standardTagsResponseType = await getStandardTags(
        await getToken()
      );
      setStandardTags(data);
    } catch {
    } finally {
      setIsSending(false);
    }
  };
  // 空白区切りの文字列に変換
  useEffect(() => {
    const convertSelectedStandardTagsToString = () => {
      setSelectedStandardTags(selectedStandardTagsArray.join(" "));
    };
    convertSelectedStandardTagsToString();
  }, [selectedStandardTagsArray]);

  //新しい記録を作成
  const createNewReport = async (
    login_user_id: number, // userResponseType.id の値
    date?: string, // yyyy/mm/dd形式
    report_type?: number, // 0：週報、1：実習、2：省察、99：その他
    standard_tags?: string, // 数値の空白区切り。数値は standardTagResponseType.id の値
    user_tags?: string, // タグ文字列の空白区切り。タグ文字列は任意。
    main_text1?: string,
    main_text2?: string
  ) => {
    setIsSending(true);
    try {
      if (! await checkValidUserId(login_user_id)) {
        return;
      }

      const requestParams: newReportRequestType = {
        login_user_id, // userResponseType.id の値
        date, // yyyy/mm/dd形式
        report_type, // 0：週報、1：実習、2：省察、99：その他
        standard_tags, // 数値の空白区切り。数値は standardTagResponseType.id の値
        user_tags, // タグ文字列の空白区切り。タグ文字列は任意。
        main_text1,
        main_text2,
      };
      const data: newReportResponseType = await newReport(
        await getToken(),
        requestParams
      );
      setReportIdState(data.id);
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "登録しました",
        type: "success",
      });
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "登録できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  // 記録編集機能のためレポート取得
  const getReportData = async (report_id: number) => {
    setIsSending(true);
    try {
      const requestParams: reportRequestType = {
        report_id,
      };
      const data: reportDetailResponseType = await getReport(
        await getToken(),
        requestParams
      );
      setReportDate(new Date(data.report_date));
      setReportType(data.report_type);
      setSelectedStandardTagsArray(data.standard_tags);
      setUserTags(data.user_tags.join(" "));
      setMainText1(data.main_text1);
      setMainText2(data.main_text2);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "記録を取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
      setEmptyReport();
    } finally {
      setIsSending(false);
    }
  };

  // 記録を編集
  const updateUserReport = async (
    login_user_id: number, // userResponseType.id の値
    date?: string, // yyyy/mm/dd形式
    report_type?: number, // 0：週報、1：実習、2：省察、99：その他
    standard_tags?: string, // 数値の空白区切り。数値は standardTagResponseType.id の値
    user_tags?: string, // タグ文字列の空白区切り。タグ文字列は任意。
    main_text1?: string,
    main_text2?: string,
    report_id?: number
  ) => {
    setIsSending(true);
    try {
      if (! await checkValidUserId(login_user_id)) {
        return;
      }
      const requestParams: updateReportRequestType = {
        login_user_id, // userResponseType.id の値
        date, // yyyy/mm/dd形式
        report_type, // 0：週報、1：実習、2：省察、99：その他
        standard_tags, // 数値の空白区切り。数値は standardTagResponseType.id の値
        user_tags, // タグ文字列の空白区切り。タグ文字列は任意。
        main_text1,
        main_text2,
        report_id,
      };
      const data: reportDetailResponseType = await updateReport(
        await getToken(),
        requestParams
      );
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "保存しました",
        type: "success",
      });
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "保存できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleSetMainText1 = (e: any) => {
    setMainText1(e.target.value);
  };
  const handleSetMainText2 = (e: any) => {
    setMainText2(e.target.value);
  };

  const handleSetUserTags = async (e: any) => {
    setUserTags(e.target.value);
  };

  // 登録ボタンを押したとき
  const handleClickCreateButton = async () => {
    await createNewReport(
      Number(userId),
      parseDate(reportDate),
      reportType,
      selectedStandardTags,
      userTags,
      mainText1,
      mainText2
    );
  };

  // 保存ボタンを押したとき
  const handleClickUpdateButton = async () => {
    await updateUserReport(
      Number(userId),
      parseDate(reportDate),
      reportType,
      selectedStandardTags,
      userTags,
      mainText1,
      mainText2,
      Number(reportIdState)
    );
  };

  // 編集可能かどうかの確認（userIdが0でないかどうか）
  // ページ表示時のハンドリングも考慮して、backend ではなく frontend で対応しておく。
  const checkValidUserId = async (userId: number) => {
    if (userId > 0) {
      return true;
    }

    let log_text = "Invalid user id. email:" + state.userEmail
    const requestParams: loggingRequestType = {
      log_text,
    };
    const result: loggingResponseType = await sendLog(
      await getToken(),
      requestParams
    );

    setFeedbackInfo({
      ...feedbackInfo,
      open: true,
      text: "ユーザ情報が不正です。ログアウト後、再ログインしてください。",
      type: "error",
    });
    return false;
  };

  // 最大文字数変数化
  const maxTextLength = 1000;

  // reportDateが今半期以降か
  const validStartDate = validStartDateFromDate(Today);
  const isReportDataValid = reportDate >= validStartDate;

  // 登録可能条件変数化
  const disableButtonCondition =
    !(Number(userId) === state.userId) ||
    mainText1?.length > maxTextLength ||
    (!mainText1 && !mainText2) ||
    mainText2?.length > maxTextLength ||
    !isReportDataValid;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  const displayText = reportIdState ? "記録詳細" : "記録作成";

  // MEMO：based on kyoai-education-app manage-students.tsx
  return (
    <>
      <Container maxWidth="xl">
        <div>
          {/* className={classes.pageTitle}> */}
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography
                variant="h5"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                {/* TODO：style記述 */}
                {displayText}
              </Typography>
            </Grid>
          </Grid>
        </div>

        {isSending ? (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <div className="text-center">
                  <CircularProgress
                    style={{ margin: "10rem auto 0 auto", textAlign: "center" }}
                    size="3rem"
                  />
                </div>
                <Typography>記録情報を取得中...</Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Box
              sx={{
                padding: "20px 0 20px 0",
              }}
            >
              <Box sx={{ width: "100%", marginX: "auto" }}>
                {
                  <FeedbackBar
                    feedbackInfo={feedbackInfo}
                    handleClose={handleClose}
                  ></FeedbackBar>
                }
                <InputFormMolecule inputLabel="日付">
                  <div className="flex items-center relative z-20">
                    <FormOutlineAtom>
                      <DatePickerComponent
                        reportDate={reportDate}
                        setReportDate={setReportDate}
                        Today={Today}
                      ></DatePickerComponent>
                    </FormOutlineAtom>
                    {!isReportDataValid && (
                      <p className="text-red-700 ml-4">
                        {`${parseDate(
                          validStartDate
                        )} 以降の日付を入力してください。`}
                      </p>
                    )}
                  </div>
                </InputFormMolecule>
                <InputFormMolecule inputLabel="種類">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={reportType}
                      onChange={(e) => {
                        setReportType(Number(e.target.value));
                      }}
                    >
                      {reportTypesArray.map((el, index) => {
                        return (
                          <React.Fragment key={index}>
                            <BasicTextAtom>
                              <FormControlLabel
                                style={{ zIndex: 0 }}
                                key={el.id}
                                value={el.id}
                                control={<Radio style={{ zIndex: 0 }} />}
                                label={el.value}
                              />
                            </BasicTextAtom>
                          </React.Fragment>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </InputFormMolecule>
                <InputFormMolecule inputLabel="鳴門パースペクティブ">
                  <FormControl
                    sx={{ minWidth: 120, width: "70%" }}
                    size="small"
                  >
                    <InputLabel
                      style={{ zIndex: 1, backgroundColor: "white" }}
                      id="standard_tags"
                    >
                      観点を複数指定できます
                    </InputLabel>
                    <Select
                      style={{ zIndex: 0 }}
                      labelId="standard_tags"
                      multiple
                      id="standard_tags"
                      value={selectedStandardTagsArray}
                      label="standard_tags"
                      renderValue={(selected) => {
                        return selected
                          .map((id: any) => {
                            return standardTags.content.filter(
                              (obj: any) => obj.id === id
                            )[0].tag_name;
                          })
                          .join(", ");
                      }}
                      onChange={(event: SelectChangeEvent<any>) => {
                        const {
                          target: { value },
                        } = event;
                        setSelectedStandardTagsArray(
                          // On autofill we get a stringified value.
                          typeof value === "string" ? value.split(",") : value
                        );
                      }}
                    >
                      {standardTags.content.map((standardTag, index) => {
                        return (
                          <MenuItem key={index} value={standardTag.id}>
                            <Checkbox
                              checked={
                                selectedStandardTagsArray.filter((id: any) => {
                                  return id === standardTag.id;
                                }).length > 0
                              }
                            />
                            <ListItemText primary={standardTag.tag_name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <a
                    className="ml-2"
                    href={process.env.REACT_APP_NARUTO_HP_URL ?? ""}
                    target="_blank"
                  >
                    <Typography className="text-blue-500">詳細説明</Typography>
                  </a>
                </InputFormMolecule>
                <InputFormMolecule inputLabel="タグ">
                  <TextField
                    label="空白区切りで複数指定できます"
                    value={userTags}
                    onChange={(e) => handleSetUserTags(e)}
                    style={{ width: "70%", zIndex: 0 }}
                  />
                </InputFormMolecule>
                <InputFormMolecule inputLabel="本文（エピソード）">
                  <Box sx={{ width: "70%" }}>
                    <FormOutlineAtom
                      error={mainText1?.length > maxTextLength && "error"}
                      mainTextLength={mainText1?.length}
                      mainText={true}
                    >
                      <textarea
                        className="p-4 focus:outline-none w-[100%]"
                        onChange={(e) => handleSetMainText1(e)}
                        value={mainText1 ?? ""}
                        placeholder="1000文字まで入力できます"
                        wrap="hard"
                        rows={4}
                      ></textarea>
                    </FormOutlineAtom>
                  </Box>
                </InputFormMolecule>
                <InputFormMolecule inputLabel="分析・考察・アクションプラン">
                  <Box sx={{ width: "70%" }}>
                    <FormOutlineAtom
                      error={mainText2?.length > maxTextLength && "error"}
                      mainTextLength={mainText2?.length}
                      mainText={true}
                    >
                      <textarea
                        className="p-4 focus:outline-none w-[100%]"
                        onChange={(e) => handleSetMainText2(e)}
                        value={mainText2 ?? ""}
                        placeholder="1000文字まで入力できます"
                        wrap="hard"
                        rows={4}
                      ></textarea>
                    </FormOutlineAtom>
                  </Box>
                </InputFormMolecule>
              </Box>
              <Box sx={{ width: "80%", marginX: "auto", textAlign: "center" }}>
                {reportIdState ? (
                  <Button
                    disabled={disableButtonCondition}
                    style={{ width: "100px", zIndex: 0 }}
                    variant="contained"
                    onClick={() => handleClickUpdateButton()}
                  >
                    保存
                  </Button>
                ) : (
                  <Button
                    disabled={disableButtonCondition}
                    style={{ width: "100px", zIndex: 0 }}
                    variant="contained"
                    onClick={() => handleClickCreateButton()}
                  >
                    登録
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};
