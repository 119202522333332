
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from "@mui/material/styles";
import { myTheme } from "./common/myTheme";
import StateProvider, { Store } from "./store";

import { RouterComponent } from "./RouterComponent";

//ログインuser情報

function App() {
  return (
    <>
      <ThemeProvider theme={myTheme}>
        <BrowserRouter>
          <StateProvider>
            <RouterComponent/>
          </StateProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;


