import React, { useState, useEffect } from "react";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Surface,
  Symbols,
  Tooltip,
} from "recharts";
import ResetIsHideMolecule from "./molecules/ResetIsHideMolecule";

export const RaderChartComponent = (props: any) => {
  const { chartData, dashboardData, type, standardTags } = props;
  const [isHide, setIsHide] = useState<
    Array<{ reportDate: string; hide: boolean } | null>
  >([]);

  // isHideに初期値を追加（両方表示）
  useEffect(() => {
    const dataType =
      type === "target_user"
        ? "standard_tag_totals_student"
        : "standard_tag_totals_all_students";
    const newIsHideArray: any[] = [];
    dashboardData[dataType].map((dataObj: any) => {
      newIsHideArray.push({
        reportDate: dataObj.report_date_half,
        hide: false,
      });
    });
    setIsHide(newIsHideArray);
  }, []);

  const dashboardDataType =
    type === "target_user"
      ? "standard_tag_totals_student"
      : "standard_tag_totals_all_students";

  // それぞれのタグの観点を作成
  const createViewpointsArray = (type: number) => {
    // 鳴門パースペクティブの該当分をフィルタ
    const filterStandardTag = (tagType: number) => {
      return standardTags.content.filter(
        (tag: { tag_name: string; id: number; tag_type: number }) => {
          return tag.tag_type === tagType;
        }
      );
    };
    return filterStandardTag(type);
  };

  // 2021H1を2021前期等に変換
  const parseEntryValue = (entryValue: any) => {
    const plainValue = entryValue.substring(0, 4);
    const parsedValue = isFirstHalf(entryValue) ? "前期" : "後期";
    return plainValue + " " + parsedValue;
  };

  const viewPointsStringArray: Array<{ label: string; tag_type: number }> = [
    { label: "C1) 構え", tag_type: 0 },
    { label: "C2) 知識", tag_type: 1 },
    { label: "C3) 技能", tag_type: 2 },
    { label: "C4) 実践的指導力", tag_type: 3 },
    { label: "T1) 人間性", tag_type: 10 },
    { label: "T2) 連携・協働力", tag_type: 11 },
    { label: "T3) 課題発見・価値創造力", tag_type: 12 },
    { label: "T4) 省察力・職能成長志向", tag_type: 13 },
  ];

  // マウスオーバーしたときに表示されるラベル
  const getViewPoints = (label: any) => {
    return viewPointsStringArray.map((viewPointsString, index) => {
      if (label === viewPointsString.label) {
        return (
          <React.Fragment key={index}>
            <p className="pt-2">-- 観点 --</p>
            <ul className="list-disc pl-4">
              {createViewpointsArray(viewPointsString.tag_type).map(
                (obj: { tag_name: string; tag_type: number; id: number }, index: number) => {
                  return obj.tag_name === "個人的指導力（ファシリテート力）" ? (
                    <li key={index}>
                      {obj.tag_name.slice(0, 6)}
                      <br />
                      {obj.tag_name.slice(6, 16)}
                    </li>
                  ) : (
                    <li key={index}>{obj.tag_name}</li>
                  );
                }
              )}
            </ul>
          </React.Fragment>
        );
      } else {
        return "";
      }
    });
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="text-left shadow-lg w-[230px] rounded-md bg-white p-2">
          <p>{`${label}`}</p>
          <div>{getViewPoints(label)}</div>
          <p className="pt-2">-- 記録数 --</p>
          <ul className="list-disc pl-4">
            {dashboardData[dashboardDataType].map(
              (report: any, index: number) => {
                return (
                  <li key={index}>{`${parseEntryValue(
                    report.report_date_half
                  )} : ${payload[index].value}回`}</li>
                );
              }
            )}
          </ul>
        </div>
      );
    }

    return null;
  };

  // @ts-ignore
  function customTick({ payload, x, y, textAnchor, stroke, radius }) {
    const payloadArray = payload.value.split("・");
    // 項目ラベルを１行で表示するか否か
    const onelinerPayload =
      payloadArray[0].startsWith("T3") === false &&
      payloadArray[0].startsWith("T4") === false;

    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <text
          radius={radius}
          stroke={stroke}
          x={x}
          y={y}
          className="text-sm"
          textAnchor={textAnchor}
        >
          <tspan x={x} dy="0em">
            {onelinerPayload && payloadArray[1]
              ? payloadArray[0] + "・" + payloadArray[1]
              : payloadArray[0]}
          </tspan>
          {onelinerPayload === false && payloadArray[1] && (
            <tspan x={x} dy="1.2em">
              ・{payloadArray[1]}
            </tspan>
          )}
        </text>
      </g>
    );
  }

  const parseRGBtoHex = (color: any) => {
    const hex = color.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const generateColor = (year: any) => {
    const r = 255 - ((year * 674) % 155);
    const g = 255 - ((year * 253) % 155);
    const b = 255 - ((year * 523) % 155);

    const hex = "#" + parseRGBtoHex(r) + parseRGBtoHex(g) + parseRGBtoHex(b);
    return hex;
  };

  // H1だったら実線であることがわかるようなiconを使用
  const isFirstHalf = (entryValue: any) => {
    return entryValue.substring(4, 6) === "H1";
  };

  // 凡例のH1を前期、H2を後期に修正
  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul className="flex items-center justify-left flex-wrap w-fit mx-auto text-center">
        {payload.map((entry: any, index: any) => {
          const { color } = entry;
          return (
            <div
              key={index}
              className="flex items-center ml-4"
              onMouseOver={() => {
                setIsHide(
                  isHide?.map((obj: any) => {
                    if (obj.reportDate === entry.value) {
                      return {
                        ...obj,
                        hide: false,
                      };
                    } else {
                      return {
                        ...obj,
                        hide: true,
                      };
                    }
                  })
                );
              }}
              // マウスを早く動かすと動作しないことがあるため、ResetIsHideコンポーネントを作成して対応
              onMouseLeave={() => {
                setIsHide(
                  isHide?.map((obj: any) => {
                    return {
                      ...obj,
                      hide: false,
                    };
                  })
                );
              }}
            >
              <Surface width={10} height={10}>
                {
                  // 前期・後期に応じてアイコン変化
                  isFirstHalf(entry.value) ? (
                    <Symbols
                      cx={5}
                      cy={5}
                      type="square"
                      size={50}
                      fill={color}
                    />
                  ) : (
                    <Symbols
                      cx={5}
                      cy={5}
                      type="circle"
                      size={10}
                      fill={color}
                    />
                  )
                }
              </Surface>
              <span
                key={`item-${index}`}
                className={`w-fit block`}
                style={{ color: color }}
              >
                {parseEntryValue(entry.value)}
              </span>
            </div>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="relative">
      {/**マウスオーバーするとisHide stateがリセットされるようなコンポーネントを作成（チャートの周りに配置） */}
      <ResetIsHideMolecule setIsHide={setIsHide} isHide={isHide} />
      <RadarChart
        cx={280}
        cy={150}
        outerRadius={100}
        width={550}
        height={350}
        data={chartData}
        className="relative hover:z-50"
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="tag_type_name" tick={customTick} />
        <PolarRadiusAxis />
        {dashboardData[dashboardDataType].map((report: any, index: number) => {
          {
            /** 年の下2桁を計算式に含んだカラーコードを採用 */
          }
          return (
            <Radar
              key={index}
              name={report.report_date_half}
              dataKey={report.report_date_half}
              stroke={generateColor(report.report_date_half.substring(2, 4))}
              strokeWidth={2}
              strokeDasharray={
                report.report_date_half.substring(4, 6) === "H2" ? "3 3" : ""
              }
              fill={generateColor(report.report_date_half.substring(2, 4))}
              fillOpacity={0.1}
              hide={
                isHide
                  ? isHide.find((obj: any) => {
                      return obj.reportDate === report.report_date_half;
                    })?.hide
                  : false
              }
            />
          );
        })}
        <Legend // 凡例をカスタマイズ
          content={renderLegend}
          wrapperStyle={{
            paddingTop: "2em",
          }}
        />
        <Tooltip
          allowEscapeViewBox={{
            x: true,
            y: true,
          }}
          position={
            type === "target_user" ? { x: 480, y: 0 } : { x: -140, y: 0 }
          }
          content={<CustomTooltip />}
        />
      </RadarChart>
    </div>
  );
};
