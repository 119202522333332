import { Box, Typography } from "@mui/material";
import { PaddingAtom } from "../atoms/PaddingAtom";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../store";
import { UserTypeStudent, UserTypeTeacher } from "../../common/type";
import { useWindowSize } from "../../utils/useWindowSize";

export const RankingMolecule = (props: any) => {
  const { dashboardData, rankingType, aggregateType, isOpen, setIsOpen } =
    props;
  const { state } = useContext(Store);
  const [width, height] = useWindowSize();
  const xl2 = 1536;

  // 変数化
  const standardRankingType = rankingType === "standard";
  const userRankingType = rankingType === "user";

  const rankingTitle = standardRankingType
    ? "鳴門パースペクティブ ランキング"
    : "オリジナルタグ ランキング";

  const dashboardDataType = standardRankingType
    ? aggregateType === "target_user"
      ? "standard_tag_ranking_student"
      : "standard_tag_ranking_all_students"
    : aggregateType === "target_user"
    ? "user_tag_ranking_student"
    : "user_tag_ranking_all_students";

  const maxLength = 60;

  const unlinkTextType =
    state.userType === UserTypeStudent && aggregateType === "all_students";

  return (
    <>
      <ul
        className={`space-y-4 text-black list-none list-inside dark:text-gray-400 text-lg`}
      >
        <li>
          <ol className="pl-5 mt-2 space-y-1 list-decimal list-inside">
            {dashboardData[dashboardDataType].map((obj: any, index: number) => {
              return (
                (index > 4 && !isOpen) || (
                  <div key={index}>
                    <PaddingAtom />
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {/**番号 */}
                      <Typography className="pr-2">{`${
                        index + 1
                      })`}</Typography>
                      {/**タグ名 */}
                      {unlinkTextType ? (
                        <Typography className="pr-2">{`#${obj.tag_name}`}</Typography>
                      ) : (
                        <Link
                          to={`/reports`}
                          state={{
                            stdTagId: standardRankingType ? obj.id : null,
                            userTagName: userRankingType ? obj.tag_name : null,
                            reportDate: null,
                            studentNumber: null,
                          }}
                        >
                          <Typography className="pr-2 hover:underline">{`#${obj.tag_name}`}</Typography>
                        </Link>
                      )}
                      {/**記録された回数 */}
                      <Typography>{`(${obj.count_reports}回)`}</Typography>
                    </Box>
                    {/**代表文 */}
                    {unlinkTextType ? (
                      <Typography className="pt-1 pl-4">{`${obj.summary_text.substring(
                        0,
                        maxLength
                      )}${
                        obj.summary_text.length > maxLength ? "..." : ""
                      }`}</Typography>
                    ) : (
                      <Link
                        className="block w-fit"
                        to={`/reports`}
                        state={{
                          stdTagId: standardRankingType ? obj.id : null,
                          userTagName: userRankingType ? obj.tag_name : null,
                          reportDate: obj.latest_report_date,
                          studentNumber: null,
                        }}
                      >
                        <Typography className="pl-4 hover:underline block w-fit">{`${obj.summary_text.substring(
                          0,
                          maxLength
                        )}${
                          obj.summary_text.length > maxLength ? "..." : ""
                        }`}</Typography>
                      </Link>
                    )}
                  </div>
                )
              );
            })}
          </ol>
        </li>
      </ul>
      {standardRankingType && (
        <>
          <PaddingAtom></PaddingAtom>
          <div className="flex items-center text-green-500 cursor-pointer justify-center  w-[200px] mx-auto">
            <Typography>
              <span onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? "戻す" : "すべて表示"}
              </span>
            </Typography>
            <svg
              data-accordion-icon
              className={`w-6 h-6 ${isOpen && "rotate-180"}  shrink-0 ml-2`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsOpen(!isOpen)}
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </>
      )}
      <PaddingAtom></PaddingAtom>
    </>
  );
};
