import React, { FC, useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
// import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// TODO：FeedbackBar は再利用（コピペ）した方がいいかも
//  import { FeedbackBar } from "../utils/feedback";

// import { Store } from "../store";
import { usersRequestType, usersResponseType } from "../common/type";
import { useAuth0Token, getUsers } from "../common/http-requests";
import { Link } from "react-router-dom";
import { InputFormMolecule } from "../components/molecules/InputFormMolecule";
import { FeedbackBar } from "../components/molecules/FeedbackBar";

//テーブルデータの型定義
const columns_detail = [
  { field: "user_name", headerName: "氏名", minWidth: 150 },
  { field: "student_number", headerName: "学籍番号", minWidth: 120 },
  { field: "count_reports", headerName: "記録数", minWidth: 140 },
];

// const useStyles = makeStyles({
//   centering: {
//     textAlign: "center",
//   },
//   pageTitle: {
//     textAlign: "center",
//     // paddingTop: "1rem",
//     // paddingBottom: "1rem",
//   },
//   textGrey: {
//     color: "#616161",
//   },
// });

const DefaultPageIndex = 0;
const DefaultCountPerPage = 10;
const DefaultCountPerPages = [10, 25, 50];

const UsersPage: FC = () => {
  // const classes = useStyles();
  // const history = useHistory();
  // const { state } = useContext(Store);
  const { getToken } = useAuth0Token();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [usageData, setUsageData] = useState([] as Array<usageInformation>);
  const [isSending, setIsSending] = useState(true);
  // const [userId, setUserId] = useState("");
  // const [feedbackInfo, setFeedbackInfo] = useState({
  //   open: false,
  //   text: "",
  //   type: "success" as "error" | "success" | "warning" | "info",
  //   autoHideDuration: null,
  // });
  const [usersData, setUsersData] = useState<usersResponseType>({
    total_count: 0,
    content: [],
  });

  //名前検索に使用する
  const [userNames, setUserNames] = useState<string>("");
  //学籍番号検索に使用する
  const [studentNumbers, setStudentNumbers] = useState<string>("");

  const [pageIndex, setPageIndex] = useState(DefaultPageIndex);
  const [countPerPage, setCountPerPage] = useState(DefaultCountPerPage);

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setFeedbackInfo({ ...feedbackInfo, open: false });
  // };

  useEffect(() => {
    getUsersData(pageIndex, countPerPage);
  }, []);

  const getUsersData = async (
    pageIndex: number,
    countPerPage: number,
    userName?: string,
    studentNumber?: string
  ) => {
    setIsSending(true);
    try {
      const requestParams: usersRequestType = {
        page_index: pageIndex,
        count_per_page: countPerPage,
        user_names: userName,
        student_numbers: studentNumber,
      };
      const data: usersResponseType = await getUsers(
        await getToken(),
        requestParams
      );
      setUsersData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "学生の利用情報の取得が出来ませんでした。ブラウザをリロードして再度お試しください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleSetUserName = (e: any) => {
    setUserNames(e.target.value);
  };

  const handleSetStudentNumber = (e: any) => {
    setStudentNumbers(e.target.value);
  };

  const handleClickSearchButton = () => {
    setPageIndex(0);
    getUsersData(0, countPerPage, userNames, studentNumbers);
  };

  const handleClickClearButton = () => {
    setUserNames("");
    setStudentNumbers("");
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  // //テーブルクリック時のイベント処理
  // const handleTableClick = (
  //   params: any,
  //   e: React.MouseEvent<Element, MouseEvent>
  // ) => {
  //   const e_target = e.target as HTMLElement;
  //   setUserId(params.row.userId);
  //   setAnchorEl(e_target);
  // };

  // const handleMenuClick = () => {
  //   if (userId === "") {
  //     history.push("/portfolio-list");
  //   } else {
  //     history.push("/portfolio-list?id=" + userId);
  //   }
  // };

  const handleChangePage = (event: any, newPage: any) => {
    setPageIndex(newPage);
    getUsersData(newPage, countPerPage, userNames, studentNumbers);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newCountPerPage = parseInt(event.target.value, DefaultCountPerPage);
    setCountPerPage(newCountPerPage);

    setPageIndex(DefaultPageIndex);
    getUsersData(DefaultPageIndex, newCountPerPage, userNames, studentNumbers);
  };

  // MEMO：based on kyoai-education-app manage-students.tsx
  return (
    <>
      <Container maxWidth="xl">
        <div>
          {/* className={classes.pageTitle}> */}
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography
                variant="h5"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                {/* TODO：style記述 */}
                学生一覧
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container alignItems="center">
            <Grid item xs={3} sm={1}>
              <Typography variant="h6" className={classes.textGrey}>
                科目
              </Typography>
            </Grid>
            <Grid item xs={6} sm={5} style={{ maxWidth: 350 }}>
              <Select
                variant="outlined"
                value={subject}
                fullWidth
                name="industry"
                onChange={handleSelect}
                style={{ backgroundColor: "#FFFFFF" }}
              >
                {userData.subjects.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid> */}
        </div>

        {isSending ? (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <div className="text-center">
                  <CircularProgress
                    style={{ margin: "10rem auto 0 auto", textAlign: "center" }}
                    size="3rem"
                  />
                </div>
                <Typography>学生情報を取得中...</Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <div className="flex mb-2 p-2 w-[80%] mx-auto">
              <div className="w-[80%]">
                <InputFormMolecule inputLabel="氏名">
                  <TextField
                    id="outlined-basic"
                    label="空白区切りで複数指定できます"
                    variant="outlined"
                    value={userNames}
                    onChange={(e) => handleSetUserName(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleClickSearchButton();
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                </InputFormMolecule>
                <InputFormMolecule inputLabel="学籍番号">
                  <TextField
                    id="outlined-basic"
                    label="空白区切りで複数指定できます"
                    variant="outlined"
                    value={studentNumbers}
                    onChange={(e) => handleSetStudentNumber(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleClickSearchButton();
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                </InputFormMolecule>
              </div>
              <div className="w-[20%] flex justify-around h-fit">
                <Button
                  style={{
                    width: "100px",
                    marginLeft: "20px",
                    fontSize: "16px",
                  }}
                  variant="contained"
                  onClick={() => handleClickSearchButton()}
                >
                  検索
                </Button>
                <Button
                  style={{
                    width: "100px",
                    marginLeft: "20px",
                    backgroundColor: "gray",
                    transitionProperty: "opacity",
                    transitionDuration: "300ms",
                    fontSize: "16px",
                  }}
                  className="hover:opacity-100 opacity-70"
                  variant="contained"
                  onClick={() => handleClickClearButton()}
                >
                  クリア
                </Button>
              </div>
            </div>
            <Paper style={{ width: "100%" }}>
              <TableContainer style={{ maxHeight: 500 }}>
                <Table aria-label="custom pagination table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns_detail.map((column) => (
                        <TableCell
                          key={column.field}
                          align="center"
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/*検索した結果filterされたusersData */}
                    {usersData.content.map((value, index) => (
                      <TableRow
                        key={index}
                        style={
                          index % 2 === 0
                            ? { backgroundColor: "whitesmoke" }
                            : { backgroundColor: "white" }
                        }
                      >
                        {/* onClick={(e) => { handleTableClick(value, e) }}> */}
                        <TableCell align="center">
                          <Link
                            className="underline"
                            to={`/dashboard`}
                            state={{
                              userId: value.id,
                              userName: value.user_name,
                            }}
                          >
                            {value.user_name}
                          </Link>
                          {/* className={classes.tableCellWithLink}> */}
                        </TableCell>
                        <TableCell align="center">
                          {value.student_number}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            className="underline"
                            to={`/reports`}
                            state={{
                              stdTagId: null,
                              userTagName: null,
                              reportDate: null,
                              studentNumber: value.student_number,
                            }}
                          >
                            {value.count_reports ? value.count_reports : 0}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={usersData.total_count}
                      rowsPerPage={countPerPage}
                      labelRowsPerPage={"ページ毎の件数"}
                      rowsPerPageOptions={DefaultCountPerPages}
                      page={pageIndex}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </>
        )}
      </Container>
    </>
  );
};

export default UsersPage;
