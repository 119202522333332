import React, { useState, useEffect, useContext } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

import { Routes, Route, Navigate } from "react-router-dom";
import { Store } from "./store";

// import pages
import Appbar from "./components/appbar";
import Sidebar from "./components/sidebar";
import UsersPage from "./pages/UsersPage";
import DashboardPage from "./pages/DashboardPage";
import { useAuth0 } from "@auth0/auth0-react";
import { ReportListPage } from "./pages/ReportListPage";
import { ReportDetailPage } from "./pages/ReportDetailPage";
import { getUser, useAuth0Token } from "./common/http-requests";
import { userResponseType } from "./common/type";
import types from "./store/types";

export const RouterComponent = () => {
  const { getToken } = useAuth0Token();
  const [isSending, setIsSending] = useState(true);

  const { user } = useAuth0();

  const userEmail = user?.email;

  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    getUserData(userEmail);
  }, []);

  const getUserData = async (email?: string) => {
    setIsSending(true);
    try {
      const requestParams: {
        email?: string;
      } = {
        email,
      };
      const data: userResponseType = await getUser(
        await getToken(),
        requestParams
      );
      dispatch({
        type: types.SET_USER_INFO,
        payload: {
          userId: data.id,
          userEmail: data.email,
          userName: data.user_name,
          userType: data.user_type,
        },
      });
    } catch {
    } finally {
      setIsSending(false);
    }
  };
  return isSending ? (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <div className="text-center">
            <CircularProgress
              style={{ margin: "10rem auto 0 auto", textAlign: "center" }}
              size="3rem"
            />
          </div>
          <Typography>記録情報を取得中...</Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Appbar />
      <Sidebar />
      <Routes>
        {/**学生一覧画面からダッシュボード画面に遷移した後、サイドバーから「ダッシュボード」に遷移した場合に
         * stateがないとホワイトアウトしてしまうためnullを指定する
         */}
        <Route path="/" element={<Navigate to="/dashboard" state={{}} />} />
        <Route
          path="/menuDashboard"
          element={<Navigate to="/dashboard" state={{}} />}
        />
        <Route
          path="/menuReports"
          element={<Navigate to="/reports" state={{}} />}
        />
        <Route
          path="/menuReportDetail"
          element={
            <Navigate
              to="/reportDetail"
              state={{
                userId: state.userId,
              }}
            />
          }
        />
        <Route
          path="/menuUsers"
          element={<Navigate to="/users" state={{}} />}
        />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/reports" element={<ReportListPage />} />
        <Route path="/reportDetail" element={<ReportDetailPage />} />
        <Route path="/users" element={<UsersPage />} />
      </Routes>
    </>
  );
};
