import { ResetIsHide } from "../atoms/ResetIsHide";

const ResetIsHideMolecule = (props: any) => {
  const { setIsHide, isHide } = props;

  const isHideActive = isHide.find(
    (obj: { reportDate: string; hide: boolean }) => {
      return obj.hide === true;
    }
  );

  return (
    <>
    {/**チャートの周りに複数配置 */}
      <ResetIsHide
        setIsHide={setIsHide}
        isHide={isHide}
        className={`absolute top-[270px] left-0 w-full h-[10%]`}
      />
      <ResetIsHide
        setIsHide={setIsHide}
        isHide={isHide}
        className={`absolute top-[350px] left-0 w-full h-[10%]`}
      />
      <ResetIsHide
        setIsHide={setIsHide}
        isHide={isHide}
        className={`absolute top-0 left-0 w-full h-[10%]`}
      />
      <ResetIsHide
        setIsHide={setIsHide}
        isHide={isHide}
        className={`absolute top-0 left-0 ${
          isHideActive ? "w-[50%]" : "w-[30%]"
        } h-[80%]`}
      />
      <ResetIsHide
        setIsHide={setIsHide}
        isHide={isHide}
        className={`absolute top-0 right-0 ${
          isHideActive ? "w-[50%]" : "w-[30%]"
        } h-[80%]`}
      />
      <ResetIsHide
        setIsHide={setIsHide}
        isHide={isHide}
        className={`absolute top-0 right-[-40px] w-[10%] h-[110%]`}
      />
      <ResetIsHide
        setIsHide={setIsHide}
        isHide={isHide}
        className={`absolute top-0 left-[-40px] w-[10%] h-[110%]`}
      />
    </>
  );
};

export default ResetIsHideMolecule;
