import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import ja from 'date-fns/locale/ja'

import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
registerLocale('ja', ja)

export const DatePickerComponent = (props: any) => {
  const {reportDate, setReportDate, Today, minDate} = props;
  return (
    <DatePicker
      className="w-[100px] outline outline-1 outline-gray-400 rounded-sm p-2"
      dateFormat="yyyy/MM/dd"
      locale="ja"
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
        <div className="flex justify-between items-center py-0 px-[15px] font-bold">
          <button className="datepicker__button" onClick={decreaseMonth}>
            {"<"}
          </button>
          <div className="flex justify-center items-center">
            <div className="mr-[7px]">{dayjs(date).year()}年</div>
            <div>{dayjs(date).month() + 1}月</div>
          </div>
          <button className="font-[#757575]" onClick={increaseMonth}>
            {">"}
          </button>
        </div>
      )}
      selected={reportDate}
      placeholderText="日付を選択"
      onChange={(selectedDate) => {
        setReportDate(selectedDate || Today);
      }}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      minDate={minDate}
    />
  );
};
