import { AppBar, ButtonBase, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { FC, useContext } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Store } from "../store";
import types from "../store/types";

const Appbar: FC = () => {
  // const location = useLocation()
  const { state, dispatch } = useContext(Store);

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          {/* {location.pathname !== "/main" && state.signInState === "SignIn" ||
            state.signInState === "EmailVerified" ? ( */}
          <IconButton
            edge="start"
            className="test"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              dispatch({ type: types.SIDEBAR_CLICK });
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* ) : (
            <div />
          )} */}
          <ButtonBase>
            <Typography variant="h6">
              鳴門教育大学　e-ポートフォリオシステム
            </Typography>
          </ButtonBase>
          <SpaceDiv></SpaceDiv>
          {state.userName !== undefined ? (
            /* TODO：ログインユーザ名の表示 */
            <Typography variant="h6">{state.userName}</Typography>
          ) : (
            <></>
          )
          }
        </Toolbar>
      </AppBar>
    </>
  );
};

const SpaceDiv = styled.div`
    flex-grow: 1;
`;

export default Appbar;
