import { Box, Typography } from "@mui/material";

export const LatestTagMolecule = (props: any) => {
  const { dashboardData, aggregateType } = props;
  const dashboardDataType =
    aggregateType === "target_user"
      ? "latest_user_tags_student"
      : "latest_user_tags_all_students";
  return (
    <ul className="space-y-4 text-black list-none list-inside dark:text-gray-400 text-lg">
      <li>
        <ol className="pl-5 mt-2 space-y-1 list-decimal list-inside">
          <Box sx={{ display: "flex" }}>
            {dashboardData[dashboardDataType].map((obj: any, index: number) => {
              return (
                <Typography className="pr-4" key={index}>
                  #{obj.tag_name}
                </Typography>
              );
            })}
          </Box>
        </ol>
      </li>
    </ul>
  );
};
