import { useAuth0 } from "@auth0/auth0-react";
import App from "../App";

function Login() {
  const { user, isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();
  if (error) {
    console.log("Auth0 authentication failed");
    return <div>Oops... {error.message}</div>;
  }
  if (isLoading) {
    return <>Loading...</>;
  }
  if (!isAuthenticated) {
    loginWithRedirect();
    return <>not authenticated</>;
  }
  if (user === undefined) {
    return <>not authenticated</>;
  }

  return <App />;
}
export default Login;
