import { useCallback } from 'react';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { loggingResponseType, userResponseType, usersResponseType, standardTagsResponseType, reportsResponseType, reportsCsvResponseType, reportDetailResponseType, dashboardResponseType } from "./type";

let client = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:8000",
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

// function sleepByPromise(sec: number) {
//   return new Promise(resolve => setTimeout(resolve, sec * 1000));
// }

// const handleError = (error: any) => {
//   alert("サーバエラー:操作を完了できませんでした。開発元にお問い合わせください。\n");
// }

export function useAuth0Token() {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const getToken = useCallback(async () => {
    if (process.env.REACT_APP_AUTH0_DOMAIN) {
      await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      return idToken?.__raw;
    } else {
      return undefined
    }
  }, [getAccessTokenSilently, getIdTokenClaims]);
  return { getToken };
}

export const sendLog = async (idToken: string | undefined, params: any /*loggingRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post<loggingResponseType>("/v1/logging", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const getUsers = async (idToken: string | undefined, params: any /*usersRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post<usersResponseType>("/v1/users", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const getUser = async (idToken: string | undefined, params: any) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post<userResponseType>("/v1/user", params, httpConfig);
    return httpData.data;
    // await sleepByPromise(1)
  } catch (e: any) {
    // handleError(e)
    throw e;
  }
};

export const getStandardTags = async (idToken: string | undefined) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.get<standardTagsResponseType>("/v1/standard_tags", httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const getReports = async (idToken: string | undefined, params: any /*reportsRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post<reportsResponseType>("/v1/reports", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const getReport = async (idToken: string | undefined, params: any /*reportRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post<reportDetailResponseType>("/v1/report", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const newReport = async (idToken: string | undefined, params: any /*newReportRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post("/v1/new_report", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const updateReport = async (idToken: string | undefined, params: any /*updateReportRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post("/v1/update_report", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const getDashboard = async (idToken: string | undefined, params: any /*dashboardRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post<dashboardResponseType>("/v1/dashboard", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

export const outputReportsCsv = async (idToken: string | undefined, params: any /*reportsRequestType*/) => {
  const httpConfig = (idToken) ? { headers: { Authorization: `Bearer ${idToken}` } } : {};
  try {
    const httpData = await client.post<reportsCsvResponseType>("/v1/reports_csv", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};
